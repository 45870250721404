<template lang='pug'>
head-meta(
  title-page="Экскурсии и дегустации – Музей «Шоколадный Выборг»",
  description="Кулинарные мастер- классы по изготовлению шоколадных фигурок, дегустационные вечера, экскурсии и тематические мероприятия. Расписание, цены и контакты."
)
the-excursion
contacts-line
</template>

<script>
import TheExcursion from '../components/excursion/TheExcursion'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'ExcursionPage',
  components: { ContactsLine, HeadMeta, TheExcursion }
}
</script>
